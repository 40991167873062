.footer {
    background-color: transparent;
    color: white;
    text-align: center;
    padding: 0.1rem;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 40px;
}

/*background-color: transparent;*/
/*color: white;*/
/*text-align: center;*/
/*padding: 0.1rem;*/
/*position: fixed;*/
/*bottom: 0;*/
/*width: 100%;*/
/*height: 40px;*/