/*.cards-grid {*/
/*    display: grid;*/
/*    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));*/
/*    gap: 40px;*/
/*    justify-items: center;*/
/*    align-items: start;*/
/*    padding: 2rem;*/
/*    max-width: 1200px;*/
/*    margin: 0 auto;*/
/*}*/

/*!*.cards-grid > *:nth-child(even) {*!*/
/*!*    margin-top: 200px; !* Adjust this value to create the zig-zag effect *!*!*/
/*!*}*!*/
