.fade {
    animation: fade-in 3s;
    margin: 0;
    min-height: 100vh;
    position: relative;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}